<template>
    <div>
        <div>
            <el-input v-model="searchName" size="mini" style="width:160px" placeholder="微信昵称" clearable></el-input>
            <el-input class="margin-l-sm" v-model="searchSn" size="mini" style="width:160px" placeholder="活动单号" clearable></el-input>

            <el-button class="margin-l-sm" size="mini" @click="search">筛选</el-button>

            <div class="clear"></div>
        </div>
        <div class="margin-t-sm">

            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            width="80"
                            label="头像">
                        <template slot-scope="scope">
                            <el-avatar :src="scope.row.user_name_avatar_url"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="用户id">
                        <template slot-scope="scope">
                            <span>{{scope.row.user_id}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="名称">
                        <template slot-scope="scope">
                            <span>{{scope.row.user_name}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                            property="created_at"
                            label="购买时间"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="活动单号">
                        <template slot-scope="scope">
                            <span>{{scope.row.sn}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="是否活动发起人"
                            width="120">
                        <template slot-scope="scope">
                            <span>{{scope.row.is_initiator === 1 ? '是' : '否'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="是否支付" width="200">
                        <template slot-scope="scope">
                            <span>{{scope.row.is_pay === 1 ? '已支付' : '否'}}</span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch " >
                <span class="fl-r padding-b-sm" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>

        </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../../../utils/tools";
    export default {
        props:['classTotal'],
        name: "student",
        data(){
            return{
                searchStudentBtn:false,
                studentName:'',
                showAddStudentDialog:false,
                searchName:'',
                searchSn:'',
                total:0,
                loading:true,
                page:1,
                pageSize:10,
                name:'',
                tableData:[],
                id:0,
                searchStudents:[],
                notData:false,
                selectStudent:[]
            }
        },
        watch:{
            classTotal:function(val){

            }
        },
        methods:{
            ...mapActions('marketing',['getPromotionUsers']),
            ...mapActions('user',['getUserList']),
            ...mapActions('common',['delete']),
            search(){
                this.getCourseStudentList()
            },
            searchStudent(){
                if(this.studentName  === ''){
                    this.$message.error('请输入昵称')
                    return
                }
                this.notData = false
                this.searchStudentBtn = true
                this.getUserList({name:this.studentName,pageSize:1000}).then(res => {
                    if(res.data.list.length === 0){
                        this.notData = true
                    }
                    this.searchStudents = res.data.list
                    this.searchStudentBtn = false
                })
            },
            showAddStudent(){
                this.showAddStudentDialog = true
            },
            hideAddStudent(){
                this.showAddStudentDialog = false
            },
            selectStudents(student){
                if(JSON.stringify(this.selectStudent).indexOf(JSON.stringify(student)) == -1){
                    this.selectStudent.push(student)
                }
            },
            subStudent(student){
                console.log(student)
                this.selectStudent.splice(this.selectStudent.indexOf(student), 1);
            },
            getCourseStudentList(){
                this.loading = true
                let data = {
                    userName:this.searchName,
                    sn:this.searchSn,
                    promotionId:this.id,
                    page:this.page,
                    pageSize:this.pageSize
                }
                this.getPromotionUsers(data).then(res => {
                    if(res.ret == 0)
                    {
                        this.total = res.data.total
                        this.tableData = res.data.list
                        this.loading = false
                    }
                })
            },
            studyProgress(row){
                this.$router.push('/studyProgress/'+row.course_id+'/userId/'+row.user_id)
                console.log(row)
            },
            percentage(p){
                return tools.percentage(p)
            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getCourseStudentList()
            },
            setPage(page){
                this.page  = page
                this.getCourseStudentList()
            },

        },
        mounted() {
            this.id = this.$route.params.id
            this.getCourseStudentList()
        }
    }
</script>

<style>
    .icon-hover{

        cursor: pointer;
        font-size: 14px;
    }
    .icon-hover:hover:before{
        font-size: 14px;
        content: "\e7a0";
    }
    .el-tag{
        height: auto;
        line-height: auto;
    }
    .el-tag .el-icon-close{
        top:5px
    }
</style>